<template>
  <v-dialog v-model="showDialog" :width="width">
    <template #activator="{ on: onDialog }">
      <v-tooltip top>
        <template #activator="{ on: onTooltip }">
          <v-btn color="primary" class="my-0" icon small v-on="onDialog">
            <v-icon v-on="onTooltip">{{ icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="secondary" tabs dark flat>
        <slot name="toolbar">
          <v-toolbar-title v-if="title">
            {{ title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
          <template v-if="Array.isArray(body)" #extension>
            <v-tabs v-model="tabRef" color="secondary" grow>
              <v-tabs-slider color="accent"></v-tabs-slider>
              <v-tab v-for="bod in body" :key="bod.title">{{ bod.title }}</v-tab>
            </v-tabs>
          </template>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="primary"
          ></v-progress-linear>
        </slot>
      </v-toolbar>
      <v-tabs-items v-if="Array.isArray(body)" v-model="tabRef">
        <v-tab-item v-for="bod in body" :key="bod.title">
          <keep-alive>
            <component :is="bod.component" v-bind="bod.props"></component>
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
      <keep-alive v-else>
        <component
          :is="body.component"
          v-bind="body.props"
          :key="body.key ? body.key : 0"
          @closeDialog="closeDialog"
          :isDialogOpen="showDialog"
        ></component>
      </keep-alive>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Dialog",
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
    body: {
      type: [Object, Array],
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: undefined,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    /* component: {
      type: Object,
      required: true,
    },
    props: {
      type: Object,
      required: false,
      default: () => ({}),
    }, */
  },
  data() {
    return {
      showDialog: false,
      tabRef: null,
    };
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style></style>
